.advertise-services-section {
  position: relative;
  background: url('../../img/developers/developers-services-bg.png') top no-repeat;
  background-size: cover;

  .container {
    padding: 60px 40px 80px;

    @media (min-width: $screen-xxl) {
      padding: calc(60 / 1440 * 100vw) 0 calc(80 / 1440 * 100vw);
    }

    @media (max-width: 1320px) {
      padding: 50px 40px 40px;
    }

    @media (max-width: $screen-md) {
      padding: 0 20px 40px;
    }
  }

  .row {
    margin: 0;
  }

  .img-wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &::after {
      content: '';
      display: block;
      z-index: 2;
      width: 100%;
      height: 300px;
      position: absolute;
      bottom: -2px;
      left: 0;
      background: linear-gradient(180deg, rgba(#08060C, 0) 0, rgba(#08060C, 1) 75%);

      @media (min-width: $screen-xxl) {
        height: calc(300 / 1440 * 100vw);
        bottom: calc(-2 / 1440 * 100vw);
      }
    }

    img {
      max-width: 1040px;
      position: absolute;
      top: 0;
      left: 520px;
      z-index: 1;

      @media (min-width: $screen-xxl) {
        width: calc(1040 / 1440 * 100vw);
        min-width: calc(940 / 1440 * 100vw);
        left: calc(520 / 1440 * 100vw);
      }

      @media (max-width: 1320px) {
        max-width: 640px;
        top: 0;
        left: 350px;
        right: 0;
        margin: 0 auto;
      }

      @media (max-width: $screen-lg) {
        max-width: 500px;
        top: 100px;
        right: 50px;
      }

      @media (max-width: $screen-md) {
        max-width: 480px;
        top: auto;
        bottom: 120px;
        left: 0;
        right: 0;
      }
    }
  }

  .light-wrap {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 0;
    margin: auto;
    max-width: 1300px;
    opacity: .8;

    @media (min-width: $screen-xxl) {
      max-width: calc(1300 / 1440 * 100vw);
    }

    img {
      width: 100%;
    }
  }

  .col-12 {
    display: flex;
    flex-direction: column;
    padding: 0;
    position: relative;
    z-index: 2;
  }

  .title-wrap {
    max-width: 650px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 80px;

    @media (min-width: $screen-xxl) {
      max-width: calc(650 / 1440 * 100vw);
      gap: calc(24 / 1440 * 100vw);
      margin-bottom: calc(80 / 1440 * 100vw);
    }

    @media (max-width: 1320px) {
      max-width: 500px;
      margin-bottom: 64px;
      justify-content: space-between;
    }

    @media (max-width: $screen-lg) {
      margin-bottom: 48px;
      max-width: 335px;
    }

    @media (max-width: $screen-md) {
      margin-bottom: 360px;
      justify-content: flex-start;
      max-width: 100%;
    }

    h2 {
      font: 900 96px/0.9 $headline-font;
      color: $white-color;
      text-transform: uppercase;

      @media (min-width: $screen-xxl) {
        font-size: calc(96 / 1440 * 100vw);
      }

      @media (min-width: 1920px) {
        font-size: calc(72 / 1920 * 100vw);
      }

      @media (max-width: 1320px) {
        font-size: 64px;
      }
    }

    p {
      color: rgba($white-color, .8);
      max-width: 440px;
      letter-spacing: calc(-16px * .05);

      @media (min-width: $screen-xxl) {
        max-width: calc(440 / 1440 * 100vw);
        letter-spacing: calc(-16 / 1440 * 5vw);
      }
    }
  }

  .item-wrap {
    display: flex;
    width: 100%;
  }

  .item-small {
    width: 440px;
    border: 2px solid rgba($white-color, .2);
    border-radius: 8px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    min-height: 245px;
    background: rgba($black-color, .2) url('../../img/main/main-help-item-bg.png') center no-repeat;
    background-size: cover;
    backdrop-filter: blur(20px);

    @media (min-width: $screen-xxl) {
      width: calc(440 / 1440 * 100vw);
      border: calc(2 / 1440 * 100vw) solid rgba($white-color, .2);
      border-radius: calc(8 / 1440 * 100vw);
      padding: calc(32 / 1440 * 100vw);
      min-height: calc(245 / 1440 * 100vw);
      backdrop-filter: blur(calc(20 / 1440 * 100vw));
    }

    @media (max-width: $screen-xl) {
      padding: 24px;
      width: 330px;
    }

    @media (max-width: $screen-md) {
      padding: 16px;
      width: 100%;
      height: auto;
      min-height: 175px;
    }

    .headline-wrap {
      display: flex;
      justify-content: space-between;
      color: $white-color;
      text-transform: uppercase;

      img {
        width: 48px;

        @media (min-width: $screen-xxl) {
          width: calc(48 / 1440 * 100vw);
        }

        @media (max-width: $screen-md) {
          width: 36px;
        }
      }
    }

    .text-wrap {
      margin: 16px 0 24px;
      color: rgba($white-color, .8);

      @media (min-width: $screen-xxl) {
        margin: calc(16 / 1440 * 100vw) 0 calc(24 / 1440 * 100vw);
      }

      p {
        font-weight: 400;
        letter-spacing: calc(-16px * .05);

        @media (min-width: $screen-xxl) {
          letter-spacing: calc(-16 / 1440 * 5vw);
        }

        @media (min-width: 1920px) {
          letter-spacing: calc(-20 / 1920 * 5vw);
        }

        @media (max-width: 1320px) {
          letter-spacing: calc(-14px * .05);
        }
      }
    }

    .link-wrap {
      margin-top: auto;
      margin-bottom: 0;
      width: 100%;

      a {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
