$hero-active-transition: 1s ease all;

.main-hero-section {
  position: relative;
  height: 100vh;
  min-height: 720px;
  // overflow: hidden;

  @media (min-width: $screen-xxl) {
    min-height: calc(720 / 1440 * 100vw);
  }

  @media (min-width: 1920px) {
    min-height: calc(720 / 1920 * 100vw);
  }

  @media (max-width: $screen-md) {
    height: auto;
    margin: 0 0 80px;
  }

  * {
    transition: $hero-active-transition !important;
  }

  &.active {

    @media (min-width: $screen-md) {
      .light-wrap {
        opacity: .8;
      }

      .bg-wrap img {
        transform: scale(1.46);
      }

      .img-wrap {
        width: 560px;
        top: 95px;
        right: 500px;
        z-index: 11;

        @media (min-width: $screen-xxl) {
          width: calc(560 / 1440 * 100vw);
          top: calc(95 / 1440 * 100vw);
          right: calc(500 / 1440 * 100vw);
        }

        @media (min-width: 1920px) {
          width: calc(680 / 1920 * 100vw);
          top: calc(105 / 1920 * 100vw);
          right: calc(700 / 1920 * 100vw);
        }

        @media (max-width: 1320px) {
          width: 580px;
          top: 125px;
          right: 330px;
        }

        @media (max-width: $screen-xl) {
          top: 150px;
          width: 460px;
        }

        @media (max-width: $screen-lg) {
          top: 230px;
          width: 420px;
          right: 350px;
        }
      }

      .particle-1-wrap {
        width: 960px;
        top: -80px;

        @media (min-width: $screen-xxl) {
          width: calc(960 / 1440 * 100vw);
          top: calc(-80 / 1440 * 100vw);
        }
      }

      .particle-2-wrap {
        width: 1160px;
        top: -350px;
        right: -700px;

        @media (min-width: $screen-xxl) {
          width: calc(1160 / 1440 * 100vw);
          top: calc(-350 / 1440 * 100vw);
          right: calc(-700 / 1440 * 100vw);
        }
      }

      .screen-1-wrap {
        transform: translateY(-130vh);
      }

      .screen-2-wrap {
        transform: none;

        .text-wrap .small-text-wrap p {

          @media (max-width: 1320px) {
            margin: 0 0 30px;
          }
        }
      }
    }
  }

  .light-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    margin: 0 auto;
    max-width: 1200px;
    opacity: 0;
    overflow: hidden;

    @media (min-width: $screen-xxl) {
      max-width: calc(1200 / 1440 * 100vw);
    }

    img {
      width: 100%;
    }
  }

  .bg-wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100vh;
    min-height: 720px;
    overflow: hidden;

    @media (min-width: $screen-xxl) {
      min-height: calc(720 / 1440 * 100vw);
    }

    @media (max-width: $screen-md) {
      position: absolute;
      height: 100%;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .img-wrap {
    width: 980px;
    position: absolute;
    z-index: 3;
    top: 10px;
    right: 60px;

    @media (min-width: $screen-xxl) {
      width: calc(980 / 1440 * 100vw);
      top: calc(10 / 1440 * 100vw);
      right: calc(60 / 1440 * 100vw);
    }

    @media (min-width: 1920px) {
      width: calc(1120 / 1920 * 100vw);
      top: calc(20 / 1920 * 100vw);
      right: calc(345 / 1920 * 100vw);
    }

    @media (max-width: 1320px) {
      width: 620px;
      top: 150px;
      right: 30px;
    }

    @media (max-width: $screen-lg) {
      top: 200px;
      max-width: 90%;
    }

    @media (max-width: $screen-md) {
      top: 490px;
      z-index: 11;
      right: 0;
      left: 20px;
      padding: 0;
    }

    @media (max-width: $screen-sm) {
      top: 540px;
    }

    img {
      width: 100%;
    }
  }

  .particle-1-wrap {
    width: 880px;
    position: absolute;
    z-index: 1;
    top: -30px;
    left: -580px;

    @media (min-width: $screen-xxl) {
      width: calc(880 / 1440 * 100vw);
      top: calc(-30 / 1440 * 100vw);
      left: calc(-580 / 1440 * 100vw);
    }

    @media (max-width: $screen-md) {
      display: none;
    }

    img {
      width: 100%;
    }
  }

  .particle-2-wrap {
    width: 880px;
    position: absolute;
    z-index: 1;
    top: -150px;
    right: -560px;

    @media (min-width: $screen-xxl) {
      width: calc(880 / 1440 * 100vw);
      top: calc(-150 / 1440 * 100vw);
      right: calc(-560 / 1440 * 100vw);
    }

    @media (max-width: $screen-md) {
      display: none;
    }

    img {
      width: 100%;
    }
  }

  .screen-1-wrap {
    padding: 50px 40px;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: absolute;
    z-index: 3;

    @media (min-width: $screen-xxl) {
      padding: calc(50 / 1440 * 100vw) calc(40 / 1440 * 100vw);
    }

    @media (min-width: 1920px) {
      padding: calc(40 / 1920 * 100vw) calc(80 / 1920 * 100vw);
    }

    @media (max-width: 1320px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 95px 40px 30px;
    }

    @media (max-width: $screen-md) {
      position: relative;
      transform: none !important;
      padding: 85px 20px 700px;
    }

    @media (max-width: $screen-sm) {
      padding: 85px 20px 105%;
    }

    .headline-wrap {
      max-width: 580px;

      @media (min-width: $screen-xxl) {
        max-width: calc(580 / 1440 * 100vw);
      }

      @media (max-width: 1320px) {
        max-width: 500px;
      }

      @media (max-width: $screen-lg) {
        max-width: 450px;
      }

      @media (max-width: $screen-md) {
        max-width: 400px;
        margin: 0 0 64px;
      }

      h1 {
        color: $white-color;
      }
    }

    .cards-wrap {
      display: flex;
      gap: 12px;

      @media (min-width: $screen-xxl) {
        gap: calc(12 / 1440 * 100vw);
      }

      @media (min-width: 1920px) {
        gap: calc(15 / 1920 * 100vw);
      }

      @media (max-width: $screen-md) {
        width: 100%;
      }

      .card-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-decoration: none;
        padding: 14px;
        width: 200px;
        min-height: 200px;
        border-radius: 8px;
        color: $white-color;
        cursor: pointer;
        background: rgba($black-color, .2) url('../../img/general/card-small-bg.png') center no-repeat;
        background-size: cover;
        backdrop-filter: blur(20px);
        border: 2px solid rgba($white-color, .2);

        @media (min-width: $screen-xxl) {
          padding: calc(14 / 1440 * 100vw);
          width: calc(200 / 1440 * 100vw);
          min-height: calc(200 / 1440 * 100vw);
          border-width: calc(2 / 1440 * 100vw);
          border-radius: calc(8 / 1440 * 100vw);
          backdrop-filter: blur(calc(20 / 1440 * 100vw));
        }

        @media (min-width: 1920px) {
          width: calc(290 / 1920 * 100vw);
          min-height: calc(290 / 1920 * 100vw);
        }

        @media (max-width: $screen-md) {
          padding: 16px;
          width: calc(50% - 6px);
          min-height: 150px;
        }

        &:hover {

          img {
            transform: translateX(5px);

            @media (min-width: $screen-xxl) {
              transform: translateX(calc(5 / 1440 * 100vw));
            }
          }
        }

        &:last-child {
          transform: translateY(-100px);

          @media (min-width: $screen-xxl) {
            transform: translateY(calc(-100 / 1440 * 100vw));
          }

          @media (max-width: $screen-md) {
            transform: translateY(-36px);
          }
        }

        h5 {
          font: 900 24px/.9 $headline-font;
          text-transform: uppercase;
          margin: 0 0 16px;

          @media (min-width: $screen-xxl) {
            font-size: calc(24 / 1440 * 100vw);
            margin: 0 0 calc(16 / 1440 * 100vw);
          }

          @media (min-width: 1920px) {
            font-size: calc(32 / 1920 * 100vw);
            max-width: 80%;
          }
        }

        p {
          line-height: 1.2;
          opacity: .8;

          @media (max-width: $screen-md) {
            margin: 0 0 8px;
          }
        }

        img {
          width: 40px;
          margin-left: auto;
          transition: $fast-transition !important;

          @media (min-width: $screen-xxl) {
            width: calc(40 / 1440 * 100vw);
          }

          @media (max-width: $screen-lg) {
            max-width: 20px;
          }
        }
      }
    }
  }

  .screen-2-wrap {
    padding: 145px 40px 60px;
    display: flex;
    justify-content: space-between;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transform: translateY(110vh);

    @media (min-width: $screen-xxl) {
      padding: calc(145 / 1440 * 100vw) calc(40 / 1440 * 100vw) calc(60 / 1440 * 100vw);
    }

    @media (min-width: 1920px) {
      padding: calc(145 / 1920 * 100vw) calc(80 / 1920 * 100vw);
    }

    @media (max-width: 1320px) {
      padding: 110px 40px 35px;
    }

    @media (max-width: $screen-md) {
      position: relative;
      padding: 0 20px;
      transform: none !important;
      flex-direction: column;
    }

    &::after {
      content: '';
      display: block;
      z-index: -1;
      width: 100%;
      height: 200px;
      position: absolute;
      bottom: -1px;
      left: 0;
      transition: none !important;
      background: linear-gradient(180deg, rgba(#08060C, 0) 0, rgba(#08060C, 1) 75%);

      @media (min-width: $screen-xxl) {
        height: calc(200 / 1440 * 100vw);
      }
    }

    .headline-wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (max-width: $screen-md) {
        margin: 0 0 40px;
      }

      h2 {
        color: $white-color;
        text-transform: uppercase;
        max-width: 450px;

        @media (min-width: $screen-xxl) {
          max-width: calc(450 / 1440 * 100vw);
        }

        @media (max-width: $screen-lg) {
          max-width: 350px;
        }
      }

      p {
        color: $white-color;
        text-transform: uppercase;
        letter-spacing: 1.6px;
        max-width: max-content;

        @media (min-width: $screen-xxl) {
          letter-spacing: calc(1.6 / 1440 * 100vw);
        }

        @media (max-width: $screen-md) {
          display: none;
        }
      }
    }

    .text-wrap {
      max-width: 440px;
      display: flex;
      flex-direction: column;

      @media (min-width: $screen-xxl) {
        max-width: calc(440 / 1440 * 100vw);
      }

      @media (max-width: 1320px) {
        max-width: 300px;
      }

      @media (max-width: $screen-md) {
        max-width: 100%;
      }

      .numbers-wrap {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;

        @media (min-width: $screen-xxl) {
          margin-bottom: calc(40 / 1440 * 100vw);
        }

        @media (max-width: $screen-md) {
          gap: 48px;
          justify-content: flex-start;
          margin-bottom: 32px;
        }

        .item {
          flex: 1;
          color: $white-color;
          max-width: max-content;
          min-width: 90px;

          @media (min-width: $screen-xxl) {
            min-width: calc(90 / 1440 * 100vw);
          }

          @media (max-width: $screen-md) {
            flex: 0;
            min-width: auto;
          }

          h3 {
            text-transform: uppercase;
            margin: 0 0 8px;

            @media (min-width: $screen-xxl) {
              margin: 0 0 calc(8 / 1440 * 100vw);
            }
          }

          p {
            opacity: .8;
            letter-spacing: -0.8px;

            @media (min-width: $screen-xxl) {
              letter-spacing: calc(-0.8 / 1440 * 100vw);
            }
          }
        }
      }

      .description-wrap {
        display: flex;
        flex-direction: column;
        color: $white-color;
        margin-bottom: 40px;
        gap: 12px;
        opacity: .8;

        @media (min-width: $screen-xxl) {
          margin-bottom: calc(40 / 1440 * 100vw);
        }

        @media (max-width: $screen-md) {
          margin: 0 0 36px;
        }

        @media (min-width: $screen-xxl) {
          gap: calc(12 / 1440 * 100vw);
        }

        p {
          letter-spacing: -0.8px;

          @media (min-width: $screen-xxl) {
            letter-spacing: calc(-0.8 / 1440 * 100vw);
          }
        }
      }

      .cta-wrap {

      }
    }
  }

  .swiper {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 10;
    overflow: initial;

    @media (max-width: $screen-md) {
      height: max-content;
      touch-action: auto !important;
    }

    .swiper-wrapper {

      @media (max-width: $screen-md) {
        display: flex;
        flex-direction: column;
        height: max-content;
        transform: none;
      }
    }

    .swiper-slide {
      overflow: initial;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;

      @media (max-width: $screen-md) {
        height: max-content !important;
      }
    }
  }
}
