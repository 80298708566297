.advertise-hero-section {
  position: relative;
  height: 100vh;
  min-height: 760px;

  @media (min-width: $screen-xxl) {
    min-height: calc(760 / 1440 * 100vw);
  }

  @media (max-width: $screen-md) {
    height: auto;
    margin: 0 0 80px;
  }

  @media (max-width: $screen-md) {
    margin: 0 0 30px;
  }

  .container, .row {
    height: 100%;
    position: relative;
    z-index: 4;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    max-width: 100vw;
    justify-content: space-between;
    padding: 110px 0 25px;
    margin: 0;

    @media (min-width: $screen-xxl) {
      padding: calc(110 / 1440 * 100vw) 0 calc(25 / 1440 * 100vw);
    }

    @media (max-width: 1320px) {
      padding: 110px 0 20px;
    }

    @media (max-width: $screen-md) {
      padding: 85px 0 20px;
      flex-direction: column;
    }

    &::after {
      content: '';
      display: block;
      z-index: -1;
      width: 120%;
      height: 200px;
      position: absolute;
      bottom: 0;
      left: -10%;
      background: linear-gradient(180deg, rgba(#08060C, 0) 0, rgba(#08060C, 1) 75%);

      @media (min-width: $screen-xxl) {
        height: calc(200 / 1440 * 100vw);
      }
    }
  }

  .row > * {
    padding: 0;
  }

  .bg-wrap {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100vh;
    min-height: 720px;
    overflow: hidden;

    @media (min-width: $screen-xxl) {
      min-height: calc(720 / 1440 * 100vw);
    }

    @media (max-width: $screen-md) {
      position: absolute;
      height: 100%;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .img-wrap {
    width: 345px;
    position: absolute;
    z-index: 2;
    bottom: 20px;
    right: 0;
    left: 0;
    margin: 0 auto;

    @media (min-width: $screen-xxl) {
      width: calc(345 / 1440 * 100vw);
      bottom: calc(20 / 1440 * 100vw);
    }

    @media (max-width: 1320px) {
      width: 300px;
      right: 30px;
    }

    @media (max-width: $screen-lg) {
      right: -150px;
    }

    @media (max-width: $screen-md) {
      max-width: 260px;
      bottom: 320px;
      right: 0;
    }

    img {
      width: 100%;
    }
  }

  .left-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 50%;
    z-index: 3;

    @media (max-width: $screen-md) {
      width: 100%;
    }

    .headline-wrap {
      max-width: 500px;
      margin-bottom: 48px;

      @media (min-width: $screen-xxl) {
        max-width: calc(500 / 1440 * 100vw);
        margin-bottom: calc(48 / 1440 * 100vw);
      }

      @media (max-width: 1320px) {
        max-width: 450px;
      }

      @media (max-width: $screen-lg) {
        max-width: 350px;
        margin-bottom: 24px;
      }

      @media (max-width: $screen-md) {
        max-width: 335px;
      }

      h1 {
        font-size: 96px;
        color: $white-color;

        @media (min-width: $screen-xxl) {
          font-size: calc(96 / 1440 * 100vw);
        }

        @media (min-width: 1920px) {
          font-size: calc(108 / 1920 * 100vw);
        }

        @media (max-width: 1320px) {
          font-size: 80px;
        }

        @media (max-width: $screen-lg) {
          font-size: 64px;
        }
      }
    }

    .text-wrap {
      max-width: 470px;

      @media (min-width: $screen-xxl) {
        max-width: calc(470 / 1440 * 100vw);
      }

      @media (max-width: 1320px) {
        max-width: 300px;
      }

      @media (max-width: $screen-md) {
        margin-bottom: 440px;
        max-width: 100%;
      }

      p {
        font: 400 16px/1.4 $main-font;
        color: rgba($white-color, .8);
        letter-spacing: calc(-16px * .05);

        @media (min-width: $screen-xxl) {
          font-size: calc(16 / 1440 * 100vw);
          letter-spacing: calc(-16 / 1440 * 5vw);
        }

        @media (max-width: 1320px) {
          font-size: 14px;
        }
      }
    }
  }

  .right-wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    width: 50%;
    z-index: 3;

    @media (max-width: $screen-md) {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }

    .items-wrap {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      width: 100%;
      margin-top: -40px;
      gap: 20px;

      @media (min-width: $screen-xxl) {
        gap: calc(20 / 1440 * 100vw);
      }

      @media (max-width: $screen-md) {
        margin-top: 32px;
      }
    }

    .item-small {
      width: 440px;
      border: 2px solid rgba($white-color, .2);
      border-radius: 8px;
      padding: 32px;
      display: flex;
      flex-direction: column;
      min-height: 245px;
      background: rgba($black-color, .2) url('../../img/main/main-help-item-bg.png') center no-repeat;
      background-size: cover;
      backdrop-filter: blur(20px);

      @media (min-width: $screen-xxl) {
        width: calc(440 / 1440 * 100vw);
        border: calc(2 / 1440 * 100vw) solid rgba($white-color, .2);
        border-radius: calc(8 / 1440 * 100vw);
        padding: calc(32 / 1440 * 100vw);
        min-height: calc(245 / 1440 * 100vw);
        backdrop-filter: blur(calc(20 / 1440 * 100vw));
      }

      @media (max-width: $screen-xl) {
        padding: 24px;
        width: 330px;
      }

      @media (max-width: $screen-md) {
        padding: 16px;
        width: 100%;
        height: auto;
        min-height: 175px;
      }

      .headline-wrap {
        display: flex;
        justify-content: space-between;
        color: $white-color;
        text-transform: uppercase;

        img {
          width: 48px;

          @media (min-width: $screen-xxl) {
            width: calc(48 / 1440 * 100vw);
          }

          @media (max-width: $screen-md) {
            width: 36px;
          }
        }
      }

      .text-wrap {
        margin: 16px 0 24px;
        color: rgba($white-color, .8);

        @media (min-width: $screen-xxl) {
          margin: calc(16 / 1440 * 100vw) 0 calc(24 / 1440 * 100vw);
        }

        p {
          font-weight: 400;
          letter-spacing: calc(-16px * .05);

          @media (min-width: $screen-xxl) {
            letter-spacing: calc(-16 / 1440 * 5vw);
          }

          @media (min-width: 1920px) {
            letter-spacing: calc(-20 / 1920 * 5vw);
          }

          @media (max-width: 1320px) {
            letter-spacing: calc(-14px * .05);
          }
        }
      }

      .link-wrap {
        margin-top: auto;
        margin-bottom: 0;
        width: 100%;

        a {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}
