@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

@font-face {
  font-family: 'Druk';
  src: url('../../fonts/Druk-Heavy.ttf') format('truetype');
  font-weight: 900;
}

// Fonts
$main-font: 'Inter', sans-serif;
$headline-font: 'Druk', sans-serif;

// 900 128/0.9
// 900 108/0.9
// 900 80/0.9
// 900 40/0.9
// 500 20/1.4
// 500 16/1.4

body {

  h1 {
    font: 900 108px/0.9 $headline-font;
    text-transform: uppercase;

    @media (min-width: $screen-xxl) {
      font-size: calc(108 / 1440 * 100vw);
    }

    @media (min-width: 1920px) {
      font-size: calc(140 / 1920 * 100vw);
    }

    @media (max-width: 1320px) {
      font-size: 88px;
    }

    @media (max-width: $screen-lg) {
      font-size: 72px;
    }
  }

  h2 {
    font: 900 128px/0.9 $headline-font;

    @media (min-width: $screen-xxl) {
      font-size: calc(128 / 1440 * 100vw);
    }

    @media (min-width: 1920px) {
      font-size: calc(140 / 1920 * 100vw);
    }

    @media (max-width: 1320px) {
      font-size: 88px;
    }

    @media (max-width: $screen-lg) {
      font-size: 72px;
    }
  }

  h3 {
    font: 900 80px/0.9 $headline-font;

    @media (min-width: $screen-xxl) {
      font-size: calc(80 / 1440 * 100vw);
    }

    @media (min-width: 1920px) {
      font-size: calc(96 / 1920 * 100vw);
    }

    @media (max-width: 1320px) {
      font-size: 64px;
    }
  }

  h4 {
    font: 900 40px/0.9 $headline-font;

    @media (min-width: $screen-xxl) {
      font-size: calc(40 / 1440 * 100vw);
    }

    @media (min-width: 1920px) {
      font-size: calc(50 / 1920 * 100vw);
    }

    @media (max-width: 1320px) {
      font-size: 32px;
    }

    @media (max-width: $screen-lg) {
      font-size: 24px;
    }
  }

  h5 {
    font: 500 20px/1.4 $main-font;

    @media (min-width: $screen-xxl) {
      font-size: calc(20 / 1440 * 100vw);
    }

    @media (min-width: 1920px) {
      font-size: calc(20 / 1920 * 100vw);
    }

    @media (max-width: 1320px) {
      font-size: 16px;
    }
  }

  p {
    font: 500 16px/1.4 $main-font;

    @media (min-width: $screen-xxl) {
      font-size: calc(16 / 1440 * 100vw);
    }

    @media (min-width: 1920px) {
      font-size: calc(20 / 1920 * 100vw);
    }

    @media (max-width: 1320px) {
      font-size: 14px;
    }
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }
}
