.main-help-section {
  padding: 100px 0;
  position: relative;

  @media (min-width: $screen-xxl) {
    padding: calc(100 / 1440 * 100vw) 0;
  }

  @media (max-width: $screen-md) {
    padding: 0 0 80px;
  }

  .light-wrap {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;
    margin: auto;
    max-width: 1300px;
    opacity: .8;

    @media (min-width: $screen-xxl) {
      max-width: calc(1300 / 1440 * 100vw);
    }

    @media (max-width: $screen-md) {
      transform: scale(2.5);
      transform-origin: top;
    }

    img {
      width: 100%;
    }
  }

  .col-12 {
    padding: 0;
  }

  .title-wrap {
    text-align: center;

    @media (max-width: $screen-md) {
      text-align: left;
      margin: 0 0 40px;
    }

    h2 {
      color: $white-color;
      text-transform: uppercase;
    }
  }

  .items-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @media (min-width: $screen-xxl) {
      gap: calc(20 / 1440 * 100vw);
    }

    @media (max-width: $screen-md) {
      gap: 16px;
    }

    .item {
      width: calc((100% - 40px) / 3);
      border: 2px solid rgba($white-color, .2);
      border-radius: 8px;
      padding: 32px;
      display: flex;
      flex-direction: column;
      height: 470px;
      background: rgba($black-color, .2) url('../../img/main/main-help-item-bg.png') center no-repeat;
      background-size: cover;
      backdrop-filter: blur(20px);

      @media (min-width: $screen-xxl) {
        width: calc((100% - (40 / 1440 * 100vw)) / 3);
        border: calc(2 / 1440 * 100vw) solid rgba($white-color, .2);
        border-radius: calc(8 / 1440 * 100vw);
        padding: calc(32 / 1440 * 100vw);
        height: calc(470 / 1440 * 100vw);
        backdrop-filter: blur(calc(20 / 1440 * 100vw));
      }

      @media (max-width: $screen-xl) {
        padding: 24px;
      }

      @media (max-width: $screen-md) {
        padding: 16px;
        width: 100%;
        height: auto;
        min-height: 340px;
      }

      .headline-wrap {
        display: flex;
        justify-content: space-between;
        color: $white-color;
        text-transform: uppercase;
      }

      .text-wrap {
        margin-top: auto;

        p {
          text-indent: 70px;
          margin: 0 0 24px;
          color: $white-color;
          opacity: .8;

          @media (min-width: $screen-xxl) {
            text-indent: calc(70 / 1440 * 100vw);
            margin: 0 0 calc(24 / 1440 * 100vw);
          }
        }

        a {
          width: 100%;
          max-width: 100%;
        }
      }

      &.item-1 {
        margin-top: -210px;

        @media (min-width: $screen-xxl) {
          margin-top: calc(-210 / 1440 * 100vw);
        }

        @media (max-width: $screen-xl) {
          margin-top: -145px;
        }

        @media (max-width: $screen-md) {
          margin-top: 0;
        }
      }

      &.item-2 {
        margin-top: -20px;

        @media (min-width: $screen-xxl) {
          margin-top: calc(-20 / 1440 * 100vw);
        }

        @media (max-width: $screen-xl) {
          margin-top: -15px;
        }

        @media (max-width: $screen-md) {
          margin-top: 0;
        }
      }

      &.item-3 {
        margin-top: -120px;

        @media (min-width: $screen-xxl) {
          margin-top: calc(-120 / 1440 * 100vw);
        }

        @media (max-width: $screen-xl) {
          margin-top: -80px;
        }

        @media (max-width: $screen-md) {
          margin-top: 0;
        }
      }
    }
  }
}
