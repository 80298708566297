.community-hero-section {
  position: relative;
  min-height: 720px;
  padding: 145px 0 0;

  @media (min-width: $screen-xxl) {
    min-height: calc(720 / 1440 * 100vw);
    padding: calc(145 / 1440 * 100vw) 0 0;
  }

  @media (min-width: 1920px) {
    padding: calc(150 / 1920 * 100vw) 0 0;
  }

  @media (max-width: 1320px) {
    padding: 115px 0 0;
  }

  @media (max-width: $screen-md) {
    height: auto;
  }

  @media (max-width: $screen-lg) {
    padding: 85px 0 0;
  }

  .col-12 {
    padding: 0;
  }

  .light-wrap {
    position: absolute;
    top: 10%;
    right: -20%;
    bottom: 0;
    z-index: 0;
    max-width: 1200px;

    @media (min-width: $screen-xxl) {
      max-width: calc(1200 / 1440 * 100vw);
    }

    @media (max-width: 1320px) {
      display: none;
    }

    img {
      width: 100%;
    }
  }

  .img-wrap {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    height: 100%;

    img {
      max-width: 1050px;
      position: absolute;
      right: -310px;
      top: 70px;
      z-index: 1;

      @media (min-width: $screen-xxl) {
        width: calc(1050 / 1440 * 100vw);
        min-width: calc(1050 / 1440 * 100vw);
        right: calc(-310 / 1440 * 100vw);
        top: calc(70 / 1440 * 100vw);
      }

      @media (min-width: 1920px) {
        width: calc(1400 / 1920 * 100vw);
        min-width: calc(1400 / 1920 * 100vw);
        top: calc(95 / 1920 * 100vw);
        right: calc(-410 / 1920 * 100vw);
      }

      @media (max-width: 1320px) {
        max-width: 740px;
        top: 120px;
        right: -180px;
      }

      @media (max-width: $screen-lg) {
        top: 80px;
        right: -200px;
        max-width: 600px;
      }

      @media (max-width: $screen-md) {
        max-width: 470px;
        top: 320px;
        right: auto;
        left: 40px;
      }
    }
  }

  .bg-wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100vh;
    min-height: 720px;
    overflow: hidden;

    @media (min-width: $screen-xxl) {
      min-height: calc(720 / 1440 * 100vw);
    }

    @media (max-width: $screen-md) {
      position: absolute;
      height: 100%;
    }

    &::after {
      content: '';
      display: block;
      z-index: 0;
      width: 100%;
      height: 200px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(#08060C, 0) 0, rgba(#08060C, 1) 75%);

      @media (min-width: $screen-xxl) {
        height: calc(200 / 1440 * 100vw);
      }
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .container {
    position: relative;
    z-index: 2;

    .text-wrap {
      max-width: 60%;

      @media (max-width: $screen-lg) {
        max-width: 100%;
      }

      h2 {
        color: $white-color;
        text-transform: uppercase;
        margin-bottom: 40px;

        @media (min-width: $screen-xxl) {
          margin-bottom: calc(40 / 1440 * 100vw);
        }

        @media (min-width: 1920px) {
          max-width: calc(830 / 1920 * 100vw);
          margin-bottom: calc(40 / 1920 * 100vw);
        }

        @media (max-width: $screen-lg) {
          max-width: 400px;
        }

        @media (max-width: $screen-md) {
          margin-bottom: 480px;
        }
      }

      h4 {
        color: $white-color;
        max-width: 520px;
        margin-bottom: 20px;
        text-transform: uppercase;

        @media (min-width: $screen-xxl) {
          margin-bottom: calc(20 / 1440 * 100vw);
          max-width: calc(420 / 1440 * 100vw);
        }
      }

      p {
        letter-spacing: -0.8px;
        color: rgba($white-color, .8);
        max-width: 575px;

        @media (min-width: $screen-xxl) {
          letter-spacing: calc(-0.8 / 1440 * 100vw);
          max-width: calc(575 / 1440 * 100vw);
        }

        @media (min-width: 1920px) {
          max-width: calc(750 / 1920 * 100vw);
        }

        @media (max-width: 1320px) {
          max-width: 460px;
        }

        @media (max-width: $screen-lg) {
          max-width: 360px;
        }

        @media (max-width: $screen-md) {
          max-width: 100%;
        }
      }
    }

    .item-wrap {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin-top: -40px;

      @media (max-width: $screen-md) {
        margin-top: 32px;
      }
    }

    .item-small {
      width: 440px;
      border: 2px solid rgba($white-color, .2);
      border-radius: 8px;
      padding: 32px;
      display: flex;
      flex-direction: column;
      height: 245px;
      background: rgba($black-color, .2) url('../../img/main/main-help-item-bg.png') center no-repeat;
      background-size: cover;
      backdrop-filter: blur(20px);

      @media (min-width: $screen-xxl) {
        width: calc(440 / 1440 * 100vw);
        border: calc(2 / 1440 * 100vw) solid rgba($white-color, .2);
        border-radius: calc(8 / 1440 * 100vw);
        padding: calc(32 / 1440 * 100vw);
        height: calc(245 / 1440 * 100vw);
        backdrop-filter: blur(calc(20 / 1440 * 100vw));
      }

      @media (max-width: $screen-xl) {
        padding: 24px;
        width: 330px;
      }

      @media (max-width: $screen-md) {
        padding: 16px;
        width: 100%;
        height: auto;
        min-height: 175px;
      }

      &.item-1 {
        margin-top: 80px;

        @media (min-width: $screen-xxl) {
          margin-top: calc(80 / 1440 * 100vw);
        }

        @media (max-width: $screen-md) {
          margin-top: 24px;
        }
      }

      .headline-wrap {
        display: flex;
        justify-content: space-between;
        color: $white-color;
        text-transform: uppercase;

        img {
          width: 48px;

          @media (min-width: $screen-xxl) {
            width: calc(48 / 1440 * 100vw);
          }

          @media (max-width: $screen-md) {
            width: 36px;
          }
        }
      }

      .link-wrap {
        margin-top: auto;
        margin-bottom: 0;
        width: 100%;

        a {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}
