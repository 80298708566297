.btn-main {
  font: 500 16px/0.9 $main-font;
  text-decoration: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  max-width: max-content;
  background: $btn-gradient;
  min-width: 200px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: $white-color;
  padding: 12px 16px;

  @media (min-width: $screen-xxl) {
    font-size: calc(16 / 1440 * 100vw);
    height: calc(56 / 1440 * 100vw);
    min-width: calc(200 / 1440 * 100vw);
    border-radius: calc(8 / 1440 * 100vw);
  }

  @media (min-width: 1920px) {
    font-size: calc(20 / 1920 * 100vw);
    height: calc(72 / 1920 * 100vw);
    min-width: calc(260 / 1920 * 100vw);
  }

  @media (max-width: 1320px) {
    min-width: 170px;
  }

  &:hover {
    color: $white-color;
    background: $btn-hover-gradient;
  }
}
