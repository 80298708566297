.advertise-specifics-section {
  padding: 96px 0;
  position: relative;

  @media (min-width: $screen-xxl) {
    padding: calc(96 / 1440 * 100vw) 0;
  }

  @media (max-width: 1320px) {
    padding: 72px 0;
  }

  @media (max-width: $screen-lg) {
    padding: 48px 0;
  }

  .col-12 {
    padding: 0;
  }

  .title-wrap {
    text-align: center;

    @media (max-width: $screen-md) {
      text-align: left;
      margin: 0 0 40px;
    }

    h2 {
      font: 900 96px/0.9 $headline-font;
      color: $white-color;
      text-transform: uppercase;
      margin-bottom: 56px;

      @media (min-width: $screen-xxl) {
        font-size: calc(96 / 1440 * 100vw);
        margin-bottom: calc(56 / 1440 * 100vw);
      }

      @media (min-width: 1920px) {
        font-size: calc(72 / 1920 * 100vw);
      }

      @media (max-width: 1320px) {
        font-size: 64px;
        margin-bottom: 48px;
      }

      @media (max-width: $screen-md) {
        margin-bottom: 32px;
      }
    }
  }

  .items-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;

    @media (min-width: $screen-xxl) {
      gap: calc(32 / 1440 * 100vw);
    }

    @media (max-width: $screen-md) {
      gap: 24px;
    }

    .item {
      width: calc((100% - 32px) / 2);
      border: 2px solid rgba($white-color, .2);
      border-radius: 8px;
      padding: 32px;
      display: flex;
      flex-direction: column;
      background: rgba($black-color, .2) url('../../img/main/main-help-item-bg.png') center no-repeat;
      background-size: cover;
      backdrop-filter: blur(20px);

      @media (min-width: $screen-xxl) {
        width: calc((100% - (32 / 1440 * 100vw)) / 2);
        border: calc(2 / 1440 * 100vw) solid rgba($white-color, .2);
        border-radius: calc(8 / 1440 * 100vw);
        padding: calc(32 / 1440 * 100vw);
        backdrop-filter: blur(calc(20 / 1440 * 100vw));
      }

      @media (max-width: $screen-xl) {
        padding: 24px;
      }

      @media (max-width: $screen-md) {
        padding: 16px;
        width: 100%;
        height: auto;
        min-height: 250px;
      }

      .headline-wrap {
        display: flex;
        justify-content: space-between;
        color: $white-color;
        text-transform: uppercase;
        margin-bottom: 48px;

        @media (min-width: $screen-xxl) {
          margin-bottom: calc(48 / 1440 * 100vw);
        }
      }

      .text-wrap {

        ol {
          margin: 0;
          padding-left: 20px;

          @media (min-width: $screen-xxl) {
            padding-left: calc(20 / 1440 * 100vw);
          }
        }

        li {
          font: 400 20px/1.4 $main-font;
          color: $white-color;
          opacity: .8;
          letter-spacing: -1px;

          @media (min-width: $screen-xxl) {
            font-size: calc(20 / 1440 * 100vw);
            letter-spacing: calc(-1 / 1440 * 100vw);
          }

          @media (min-width: $screen-xxl) {
            font-size: calc(16 / 1440 * 100vw);
          }

          @media (max-width: 1320px) {
            font-size: 14px;
          }

          br {
            @media (max-width: $screen-md) {
              display: none;
            }
          }
        }
      }
    }
  }
}
