.community-require-section {
  padding: 170px 0 90px;
  position: relative;

  @media (min-width: $screen-xxl) {
    padding: calc(170 / 1440 * 100vw) 0 calc(90 / 1440 * 100vw);
  }

  @media (min-width: 1920px) {
    padding: calc(150 / 1920 * 100vw) 0 calc(80 / 1920 * 100vw);
  }

  @media (max-width: 1320px) {
    padding: 120px 0 0;
  }

  @media (max-width: $screen-md) {
    padding: 0 0 60px;
  }

  &.fixed-headline .title-wrap h2 {
    position: fixed;
    top: 170px;

    @media (min-width: $screen-xxl) {
      top: calc(170 / 1440 * 100vw);
    }

    @media (min-width: 1920px) {
      top: calc(150 / 1920 * 100vw);
    }

    @media (max-width: 1320px) {
      top: 120px;
    }

    @media (max-width: $screen-md) {
      position: relative;
      top: 0;
    }
  }

  .col-md-4, .col-md-8 {
    padding: 0;
  }

  .light-wrap {
    position: absolute;
    top: -10%;
    bottom: 0;
    left: -30%;
    z-index: -1;
    margin: auto;
    max-width: 1300px;
    opacity: .8;

    @media (min-width: $screen-xxl) {
      max-width: calc(1300 / 1440 * 100vw);
    }

    @media (max-width: $screen-md) {
      transform: scale(2.5);
      transform-origin: top;
      display: none;
    }

    img {
      width: 100%;
    }
  }

  .title-wrap {

    @media (max-width: $screen-md) {
      margin: 60px 0 32px;
    }

    h2 {
      color: $white-color;
      text-transform: uppercase;
      font: 900 96px/0.9 $headline-font;
      transition: $fast-transition;

      @media (min-width: $screen-xxl) {
        font-size: calc(96 / 1440 * 100vw);
      }

      @media (min-width: 1920px) {
        font-size: calc(110 / 1920 * 100vw);
      }

      @media (max-width: 1320px) {
        font-size: 80px;
      }

      @media (max-width: $screen-lg) {
        font-size: 56px;
        max-width: 400px;
      }

      @media (max-width: $screen-md) {
        font-size: 72px;
      }
    }
  }

  .items-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;

    @media (max-width: 1320px) {
      gap: 20px;
    }

    @media (max-width: $screen-md) {
      gap: 16px;
    }

    .item {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 32px;
      padding: 40px 95px 40px 32px;
      border-radius: 8px;
      border: 2px solid rgba($white-color, .2);
      background-color: rgba($black-color, .2);
      backdrop-filter: blur(20px);

      @media (max-width: $screen-xl) {
        padding: 32px 24px;
      }

      @media (max-width: $screen-md) {
        gap: 16px;
      }

      h4 {
        font: 900 40px/0.9 $headline-font;
        color: $white-color;
        text-transform: uppercase;

        @media (min-width: $screen-xxl) {
          font-size: calc(40 / 1440 * 100vw);
        }

        @media (max-width: 1320px) {
          font-size: 32px;
        }

        @media (max-width: $screen-lg) {
          font-size: 24px;
        }
      }

      p {
        font: 500 16px/1.4 $main-font;
        color: rgba($white-color, .8);
        letter-spacing: -0.8px;

        @media (min-width: $screen-xxl) {
          font-size: calc(16 / 1440 * 100vw);
          letter-spacing: calc(-0.8 / 1440 * 100vw);
        }

        @media (max-width: 1320px) {
          font-size: 14px;
        }
      }
    }

    .item-small {
      width: calc(50% - 16px);
      border: 2px solid rgba($white-color, .2);
      border-radius: 8px;
      padding: 32px;
      display: flex;
      flex-direction: column;
      height: 245px;
      background: rgba($black-color, .2) url('../../img/main/main-help-item-bg.png') center no-repeat;
      background-size: cover;
      backdrop-filter: blur(20px);

      @media (min-width: $screen-xxl) {
        width: calc(50% - (16 / 1440 * 100vw));
        border: calc(2 / 1440 * 100vw) solid rgba($white-color, .2);
        border-radius: calc(8 / 1440 * 100vw);
        padding: calc(32 / 1440 * 100vw);
        height: calc(245 / 1440 * 100vw);
        backdrop-filter: blur(calc(20 / 1440 * 100vw));
      }

      @media (max-width: $screen-xl) {
        padding: 24px;
      }

      @media (max-width: $screen-lg) {
        padding: 16px;
        width: 100%;
        height: auto;
        min-height: 175px;
      }

      &.item-1 {
        margin-top: 80px;

        @media (min-width: $screen-xxl) {
          margin-top: calc(80 / 1440 * 100vw);
        }

        @media (max-width: $screen-md) {
          margin-top: 24px;
        }
      }

      .headline-wrap {
        display: flex;
        justify-content: space-between;
        color: $white-color;
        text-transform: uppercase;

        img {
          width: 48px;

          @media (min-width: $screen-xxl) {
            width: calc(48 / 1440 * 100vw);
          }

          @media (max-width: $screen-md) {
            width: 36px;
          }
        }
      }

      .text-wrap {
        margin-top: auto;
        margin-bottom: 0;
        width: 100%;

        a {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}
