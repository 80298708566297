// Variables
@import "../vars/breakpoints";
@import "../vars/colors";
@import "../vars/transitions";
@import "../vars/typography";
@import "../vars/buttons";

html body {
  padding: 0;
  margin: 0;
  max-width: 100vw;
  overflow-x: hidden;
  background-color: #08060C;
  -ms-overflow-style: none;
  scrollbar-width: none;
  touch-action: pan-x pan-y;

  &::-webkit-scrollbar {
    display: none;
  }

  &.hidden #root {

    @media (min-width: $screen-md) {
      overflow: hidden;
      max-height: calc(100vh + 1px);
    }
  }

  &.overflow-hidden {
    overflow: hidden;

    @media (max-width: $screen-md) {
      overflow-y: auto !important;
    }
  }

  #root *:not([data-aos]) {
    transition: $fast-transition;
  }
}

body .container {

  @media (min-width: $screen-xxl) {
    max-width: calc(1360 / 1440 * 100vw);
  }

  @media (min-width: 1920px) {
    max-width: calc(1760 / 1920 * 100vw);
  }

  @media (max-width: $screen-xxl) {
    max-width: 100%;
    padding: 0 40px;
  }

  @media (max-width: $screen-md) {
    padding: 0 20px;
  }

  .row {
    @media (max-width: $screen-xxl) {
      margin: 0;
    }
  }
}

[class^="col-"] {
  padding: 0 8px;
}

main {
  overflow: hidden;

  section {
    position: relative;
    width: 100vw;
    z-index: 3;

    @media (max-width: $screen-xxl) {
      width: 100%;
    }
  }
}

.display-none {
  display: none !important;
}

.mobile-only {
  display: none !important;

  @media (max-width: $screen-lg) {
    display: flex !important;
  }
}

body .parallax {
  transition: none !important;
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: none;
  background-color: #08060C;

  @media (max-width: $screen-xl) {
    height: var(--app-height);
  }

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 2px;
    border: 4px solid #3E4CED;
    border-radius: 50%;
    animation: preloader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #3E4CED transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: $screen-lg) {
  [data-aos] {
    pointer-events: auto !important;
    transform: none !important;
  }

  html:not(.no-js) [data-aos^=fade][data-aos^=fade] {
    opacity: 1 !important;
  }

  html:not(.no-js) [data-aos=fade-up] {
    transform: none !important;
  }
}
