.main-reviews-section {
  padding: 0 0 120px;
  position: relative;

  @media (min-width: $screen-xxl) {
    padding: 0 0 calc(120 / 1440 * 100vw);
  }

  @media (max-width: $screen-lg) {
    padding: 0 0 80px;
  }

  .light-wrap {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;
    margin: auto;
    max-width: 1300px;
    opacity: .8;

    @media (min-width: $screen-xxl) {
      max-width: calc(1300 / 1440 * 100vw);
    }

    @media (max-width: 1320px) {
      left: -60%;
    }

    @media (max-width: $screen-md) {
      left: 0;
      transform: scale(2.5);
      transform-origin: top;
    }

    img {
      width: 100%;
    }
  }

  .col-12 {
    padding: 0;
    width: 100%;
    position: relative;

    @media (max-width: 1320px) {
      width: 50%;
    }

    @media (max-width: $screen-md) {
      width: 100%;
    }
  }

  .title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;

    h2 {
      color: $white-color;
      text-transform: uppercase;
    }

    .small-text-wrap {
      margin: 10px 0 0;

      @media (min-width: $screen-xxl) {
        margin: calc(10 / 1440 * 100vw);
      }

      @media (max-width: 1320px) {
        position: absolute;
        bottom: 70px;
        margin: 0;
        left: 100%;
      }

      @media (max-width: $screen-md) {
        bottom: -240px;
        left: 0;
      }

      p {
        color: $white-color;
        text-transform: uppercase;
        letter-spacing: 1.6px;
        max-width: max-content;

        @media (min-width: $screen-xxl) {
          letter-spacing: calc(1.6 / 1440 * 100vw);
        }
      }
    }
  }

  .img-wrap {
    max-width: 710px;
    z-index: 2;
    position: absolute;
    top: 55px;
    left: 260px;

    @media (min-width: $screen-xxl) {
      width: calc(710 / 1440 * 100vw);
      min-width: calc(710 / 1440 * 100vw);
      top: calc(55 / 1440 * 100vw);
      left: calc(260 / 1440 * 100vw);
    }

    @media (min-width: 1920px) {
      width: calc(880 / 1920 * 100vw);
      min-width: calc(880 / 1920 * 100vw);
      left: calc(370 / 1920 * 100vw);
    }

    @media (max-width: 1320px) {
      top: 100px;
      left: -70px;
      max-width: 560px;
    }

    @media (max-width: $screen-md) {
      position: relative;
      top: -40px;
      left: -60px;
      width: 100%;
      z-index: 0;
    }

    img {
      width: 100%;

      @media (max-width: $screen-md) {
        width: calc(100% + 120px);
      }
    }
  }

  .swiper {
    margin: -60px 0 0;
    position: relative;

    @media (min-width: $screen-xxl) {
      margin: calc(-60 / 1440 * 100vw) 0 0;
    }

    @media (max-width: 1320px) {
      margin: 0;
      padding: 0 0 240px;
    }

    @media (max-width: $screen-xl) {
      padding: 0 0 180px;
    }

    @media (max-width: $screen-md) {
      padding: 0 0 72px;
      margin-top: -120px;
    }

    .swiper-slide {
      border: 2px solid rgba($white-color, .2);
      border-radius: 8px;
      padding: 32px;
      display: flex;
      flex-direction: column;
      height: 500px;
      background: rgba($black-color, .2) url('../../img/main/main-help-item-bg.png') center no-repeat;
      background-size: cover;
      backdrop-filter: blur(20px);

      @media (min-width: $screen-xxl) {
        border: calc(2 / 1440 * 100vw) solid rgba($white-color, .2);
        border-radius: calc(8 / 1440 * 100vw);
        padding: calc(32 / 1440 * 100vw);
        height: calc(500 / 1440 * 100vw);
        backdrop-filter: blur(calc(20 / 1440 * 100vw));
      }

      @media (min-width: 1920px) {
        padding: calc(40 / 1920 * 100vw);
      }

      @media (max-width: 1320px) {
        padding: 24px;
      }

      @media (max-width: $screen-md) {
        height: auto;
        min-height: 270px;
      }

      &:nth-of-type(2n-1) {
        margin-top: 275px;

        @media (min-width: $screen-xxl) {
          margin-top: calc(275 / 1440 * 100vw);
        }

        @media (max-width: 1320px) {
          margin-top: 0;
        }
      }

      &:nth-of-type(2n) {

        .headline-wrap .quote {
          font-size: 260px;

          @media (min-width: $screen-xxl) {
            font-size: calc(260 / 1440 * 100vw);
          }

          @media (max-width: 1320px) {
            font-size: 140px;
          }
        }
      }

      .headline-wrap {
        display: flex;
        justify-content: space-between;
        color: $white-color;
        text-transform: uppercase;

        .quote {
          font: 900 180px/.7 $headline-font;

          @media (min-width: $screen-xxl) {
            font-size: calc(180 / 1440 * 100vw);
          }

          @media (max-width: 1320px) {
            font-size: 140px;
          }
        }

        .info-wrap {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 8px;

          h5 {
            font: 900 24px/.9 $headline-font;

            @media (max-width: $screen-lg) {
              font-size: 18px;
            }
          }
        }
      }

      .text-wrap {
        margin-top: auto;

        p {
          text-indent: 70px;
          color: $white-color;
          text-align: justify;
          opacity: .8;

          @media (min-width: $screen-xxl) {
            text-indent: calc(70 / 1440 * 100vw);
          }
        }
      }
    }

    .swiper-button-prev, .swiper-button-next {
      color: $white-color;
      bottom: 0;
      right: 0;
      top: auto;
      left: auto;
      height: 56px;
      width: 56px;
      border-radius: 8px;
      border: 2px solid rgba($white-color, .5);

      @media (min-width: $screen-xxl) {
        width: calc(56 / 1440 * 100vw);
        height: calc(56 / 1440 * 100vw);
        border-width: calc(2 / 1440 * 100vw);
        border-radius: calc(8 / 1440 * 100vw);
      }

      @media (max-width: 1320px) {
        bottom: 70px;
      }

      @media (max-width: $screen-md) {
        bottom: 0;
      }

      &::after {
        font-size: 14px;

        @media (min-width: $screen-xxl) {
          font-size: calc(14 / 1440 * 100vw);
        }
      }

      &:hover {
        background-color: rgba($white-color, .1);
      }
    }

    .swiper-button-prev {
      right: 64px;

      @media (min-width: $screen-xxl) {
        right: calc(64 / 1440 * 100vw);
      }
    }
  }
}
