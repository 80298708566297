.main-telegram-section {
  position: relative;
  background: url('../../img/main/main-telegram-bg.png') top no-repeat;
  background-size: cover;

  .img-wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &::after {
      content: '';
      display: block;
      z-index: 2;
      width: 100%;
      height: 300px;
      position: absolute;
      bottom: -2px;
      left: 0;
      background: linear-gradient(180deg, rgba(#08060C, 0) 0, rgba(#08060C, 1) 75%);

      @media (min-width: $screen-xxl) {
        height: calc(300 / 1440 * 100vw);
        bottom: calc(-2 / 1440 * 100vw);
      }
    }

    img {
      max-width: 1050px;
      position: absolute;
      right: -110px;
      top: -30px;
      z-index: 1;

      @media (min-width: $screen-xxl) {
        width: calc(1050 / 1440 * 100vw);
        min-width: calc(1050 / 1440 * 100vw);
        right: calc(-110 / 1440 * 100vw);
        top: calc(-30 / 1440 * 100vw);
      }

      @media (max-width: 1320px) {
        max-width: 680px;
        top: 0;
        right: -140px;
      }

      @media (max-width: $screen-md) {
        max-width: 550px;
        top: 300px;
        left: -30px;
        right: auto;
      }
    }
  }

  .light-wrap {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 0;
    margin: auto;
    max-width: 1300px;
    opacity: .8;

    @media (min-width: $screen-xxl) {
      max-width: calc(1300 / 1440 * 100vw);
    }

    img {
      width: 100%;
    }
  }

  .col-12 {
    padding: 50px 0;
    position: relative;
    z-index: 2;

    @media (min-width: $screen-xxl) {
      padding: calc(50 / 1440 * 100vw) 0;
    }

    @media (max-width: 1320px) {
      padding: 30px 0;
    }
  }

  .title-wrap {
    max-width: 650px;
    margin: 0 0 55px;

    @media (min-width: $screen-xxl) {
      max-width: calc(650 / 1440 * 100vw);
      margin: 0 0 calc(55 / 1440 * 100vw);
    }

    @media (min-width: 1920px) {
      max-width: calc(600 / 1920 * 100vw);
      margin: 0 0 calc(185 / 1920 * 100vw);
    }

    @media (max-width: 1320px) {
      max-width: 590px;
      margin: 0 0 175px;
    }

    @media (max-width: $screen-lg) {
      max-width: 335px;
    }

    @media (max-width: $screen-md) {
      margin: 0 0 400px;
    }

    h2 {
      color: $white-color;
      text-transform: uppercase;
    }
  }

  .card-wrap {
    text-decoration: none;

    .card-item {
      display: flex;
      flex-direction: column;
      padding: 24px;
      border-radius: 8px;
      max-width: 330px;
      color: $white-color;
      background: $card-gradient;

      @media (min-width: $screen-xxl) {
        padding: calc(24 / 1440 * 100vw);
        max-width: calc(330 / 1440 * 100vw);
        border-radius: calc(8 / 1440 * 100vw);
      }

      @media (max-width: $screen-md) {
        padding: 16px;
      }

      &:hover {

        img {
          transform: translateX(5px);

          @media (min-width: $screen-xxl) {
            transform: translateX(calc(5 / 1440 * 100vw));
          }
        }
      }

      h4 {
        text-transform: uppercase;
        margin: 0 0 16px;

        @media (min-width: $screen-xxl) {
          margin: 0 0 calc(16 / 1440 * 100vw);
        }
      }

      p {

        @media (max-width: $screen-lg) {
          margin-bottom: 8px;
        }
      }

      img {
        margin-left: auto;

        @media (max-width: $screen-lg) {
          max-width: 20px;
        }
      }
    }
  }
}
