footer {
  padding: 40px 0;
  position: relative;
  z-index: 10;

  @media (min-width: $screen-xxl) {
    padding: calc(40 / 1440 * 100vw) 0;
  }

  @media (min-width: 1920px) {
    padding: calc(48 / 1920 * 100vw) 0;
  }

  @media (max-width: $screen-md) {
    padding: 20px 0;
  }

  &.footer-influencers {
    margin-top: -160px;
    background: rgba(8, 6, 12, .4);
    backdrop-filter: blur(20px);

    @media (min-width: $screen-xxl) {
      margin-top: calc(-160 / 1440 * 100vw);
      backdrop-filter: blur(calc(20 / 1440 * 100vw));
    }

    @media (max-width: 1320px) {
      margin-top: -120px;
    }

    @media (max-width: $screen-lg) {
      margin-top: 0;
    }

    @media (max-width: $screen-md) {
      backdrop-filter: none;
    }
  }

  .col-12, .col-6 {
    padding: 0;
  }

  .logo-wrap {

    @media (max-width: $screen-md) {
      margin: 0 0 48px;
    }

    img {
      width: 95px;

      @media (min-width: $screen-xxl) {
        width: calc(95 / 1440 * 100vw);
      }
    }
  }

  .links-wrap {
    display: flex;
    justify-content: flex-end;

    ul {
      display: flex;
      flex-direction: column;
      gap: 15px;
      list-style-type: none;
      padding: 0;
      margin: 0;

      @media (min-width: $screen-xxl) {
        gap: calc(15 / 1440 * 100vw);
      }

      @media (max-width: $screen-md) {
        gap: 24px;
      }

      &.socials-wrap {
        display: flex;
        flex-direction: row;
        gap: 8px;

        @media (min-width: $screen-xxl) {
          gap: calc(8 / 1440 * 100vw);
        }

        @media (max-width: $screen-md) {
          position: absolute;
          top: 8px;
          right: 20px;
        }
      }

      a {
        font: 400 16px/.9 $main-font;
        color: $white-color;
        text-decoration: none;

        @media (min-width: $screen-xxl) {
          font-size: calc(16 / 1440 * 100vw);
        }

        img {
          width: 56px;

          @media (min-width: $screen-xxl) {
            width: calc(56 / 1440 * 100vw);
          }
        }
      }
    }
  }

  .rights-wrap {
    //display: flex;
    //justify-content: space-between;
    margin: 50px 0 0;
    display: none;

    @media (min-width: $screen-xxl) {
      margin: calc(50 / 1440 * 100vw) 0 0;
    }

    @media (max-width: $screen-md) {
      justify-content: center;
      gap: 8px;
    }

    p {
      color: $white-color;
      opacity: .48;
    }
  }
}
