.community-future-section {
  position: relative;
  min-height: 710px;

  @media (min-width: $screen-xxl) {
    min-height: calc(710 / 1440 * 100vw);
  }

  @media (max-width: $screen-md) {
    height: auto;
    margin: 0 0 80px;
  }

  @media (max-width: $screen-md) {
    margin: 0 0 30px;
  }

  .container, .row {
    height: 100%;
    position: relative;
    z-index: 4;
    min-height: 710px;

    @media (min-width: $screen-xxl) {
      min-height: calc(710 / 1440 * 100vw);
    }
  }

  .container {
    padding: 0;
    max-width: 100vw;
  }

  .row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 130px 40px 50px;

    @media (min-width: $screen-xxl) {
      padding: calc(130 / 1440 * 100vw) calc(40 / 1440 * 100vw) calc(50 / 1440 * 100vw);
    }

    @media (max-width: 1320px) {
      padding: 110px 40px 40px;
    }

    @media (max-width: $screen-md) {
      padding: 85px 20px 30px;
    }

    &::after {
      content: '';
      display: block;
      z-index: -1;
      width: 100%;
      height: 200px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(#08060C, 0) 0, rgba(#08060C, 1) 75%);

      @media (min-width: $screen-xxl) {
        height: calc(200 / 1440 * 100vw);
      }
    }
  }

  .row > * {
    padding: 0;
  }

  .bg-wrap {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100vh;
    min-height: 720px;
    overflow: hidden;

    @media (min-width: $screen-xxl) {
      min-height: calc(720 / 1440 * 100vw);
    }

    @media (max-width: $screen-md) {
      position: absolute;
      height: 100%;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .img-wrap {
    width: 345px;
    position: absolute;
    z-index: 2;
    bottom: 40px;
    right: 0;
    left: 0;
    margin: 0 auto;

    @media (min-width: $screen-xxl) {
      width: calc(345 / 1440 * 100vw);
      bottom: calc(40 / 1440 * 100vw);
    }

    @media (max-width: 1320px) {
      width: 300px;
      right: 30px;
    }

    @media (max-width: $screen-lg) {
      right: 270px;
    }

    @media (max-width: $screen-md) {
      max-width: 220px;
      bottom: 100px;
    }

    @media (max-width: $screen-sm) {
      right: 0;
    }

    img {
      width: 100%;
    }
  }

  .top-wrap {
    display: flex;
    justify-content: space-between;
    z-index: 3;
    position: relative;

    @media (max-width: $screen-md) {
      flex-direction: column;
    }

    .headline-wrap {
      max-width: 650px;

      @media (min-width: $screen-xxl) {
        max-width: calc(650 / 1440 * 100vw);
      }

      @media (max-width: 1320px) {
        max-width: 535px;
      }

      @media (max-width: $screen-lg) {
        max-width: 350px;
      }

      @media (max-width: $screen-md) {
        max-width: 335px;
        margin: 0 0 40px;
      }

      h1 {
        color: $white-color;
      }
    }

    .small-text-wrap {
      width: 440px;
      text-transform: uppercase;
      color: $white-color;

      @media (min-width: $screen-xxl) {
        width: calc(440 / 1440 * 100vw);
      }

      @media (max-width: 1320px) {
        width: 300px;
      }

      @media (max-width: $screen-md) {
        margin-bottom: 24px;
      }

      h4 {
        font: 900 40px/0.9 $headline-font;
        max-width: 390px;

        @media (min-width: $screen-xxl) {
          font-size: calc(40 / 1440 * 100vw);
          max-width: calc(390 / 1440 * 100vw);
        }

        @media (max-width: 1320px) {
          font-size: 32px;
        }

        @media (max-width: $screen-lg) {
          font-size: 24px;
          max-width: 100%;
        }
      }

      @media (max-width: $screen-md) {
        order: 2;
      }
    }
  }

  .bottom-wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    z-index: 3;

    @media (max-width: $screen-md) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    .card-wrap {
      max-width: 330px;

      @media (min-width: $screen-xxl) {
        max-width: calc(330 / 1440 * 100vw);
      }

      @media (max-width: $screen-md) {
        width: 100%;
        max-width: 375px;
      }

      .card-item {
        display: flex;
        flex-direction: column;
        padding: 24px;
        border-radius: 8px;
        color: $white-color;
        background: $card-gradient;

        @media (min-width: $screen-xxl) {
          padding: calc(24 / 1440 * 100vw);
          border-radius: calc(8 / 1440 * 100vw);
        }

        @media (max-width: $screen-md) {
          padding: 16px;
        }

        h4 {
          text-transform: uppercase;
          margin: 0 0 16px;
        }

        p {
          line-height: 1.2;
          opacity: .8;

          @media (max-width: $screen-md) {
            margin: 0 0 8px;
          }
        }

        img {
          margin-left: auto;

          @media (min-width: $screen-xxl) {
            width: calc(28 / 1440 * 100vw);
          }

          @media (max-width: $screen-lg) {
            max-width: 20px;
          }
        }
      }
    }

    .text-wrap {
      max-width: 440px;

      @media (min-width: $screen-xxl) {
        max-width: calc(440 / 1440 * 100vw);
      }

      @media (max-width: 1320px) {
        max-width: 300px;
      }

      @media (max-width: $screen-md) {
        margin-bottom: 350px;
        max-width: 100%;
      }

      ol {
        margin: 0;
        padding-left: 15px;

        @media (min-width: $screen-xxl) {
          padding-left: calc(15 / 1440 * 100vw);
        }
      }

      li {
        font: 500 20px/1.4 $main-font;
        color: $white-color;
        opacity: .8;
        letter-spacing: -1px;

        @media (min-width: $screen-xxl) {
          font-size: calc(20 / 1440 * 100vw);
          letter-spacing: calc(-1 / 1440 * 100vw);
        }

        @media (min-width: $screen-xxl) {
          font-size: calc(16 / 1440 * 100vw);
        }

        @media (max-width: 1320px) {
          font-size: 14px;
        }

        br {
          @media (max-width: $screen-md) {
            display: none;
          }
        }
      }

      p {
        font: 500 16px/1.4 $main-font;
        color: rgba($white-color, .8);

        @media (min-width: $screen-xxl) {
          font-size: calc(16 / 1440 * 100vw);
        }

        @media (max-width: 1320px) {
          font-size: 14px;
        }
      }
    }
  }
}
