.main-require-section {
  padding: 120px 0;
  position: relative;

  @media (min-width: $screen-xxl) {
    padding: calc(120 / 1440 * 100vw) 0;
  }

  @media (max-width: $screen-md) {
    padding: 0 0 60px;
  }

  //&.fixed-headline .title-wrap h2 {
  //  position: fixed;
  //  top: 120px;
  //
  //  @media (min-width: $screen-xxl) {
  //    top: calc(120 / 1440 * 100vw);
  //  }
  //
  //  @media (max-width: $screen-md) {
  //    position: relative;
  //    top: 0;
  //  }
  //}

  .col-md-4, .col-md-8 {
    padding: 0;
  }

  .light-wrap {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -25%;
    z-index: -1;
    margin: auto;
    max-width: 1300px;
    opacity: .8;

    @media (min-width: $screen-xxl) {
      max-width: calc(1300 / 1440 * 100vw);
    }

    @media (max-width: $screen-md) {
      transform: scale(2.5);
      transform-origin: top;
      display: none;
    }

    img {
      width: 100%;
    }
  }

  .title-wrap {

    @media (min-width: $screen-xxl) {
      margin-bottom: calc(40 / 1440 * 100vw);
    }

    @media (max-width: $screen-md) {
      margin-bottom: 40px;
    }

    h2 {
      color: $white-color;
      text-transform: uppercase;

      @media (max-width: $screen-lg) {
        max-width: 400px;
      }
    }
  }

  .items-wrap {
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media (max-width: $screen-md) {
      gap: 16px;
    }

    .item {
      display: flex;
      flex-direction: column;
      gap: 32px;
      padding: 40px 95px 40px 32px;
      border-radius: 8px;
      border: 2px solid rgba($white-color, .2);
      background-color: rgba($black-color, .2);
      backdrop-filter: blur(20px);

      @media (min-width: $screen-xxl) {
        gap: calc(32 / 1440 * 100vw);
        border-radius: calc(8 / 1440 * 100vw);
        padding: calc(40 / 1440 * 100vw) calc(95 / 1440 * 100vw) calc(40 / 1440 * 100vw) calc(32 / 1440 * 100vw);
      }

      @media (max-width: $screen-xl) {
        padding: 32px 24px;
      }

      @media (max-width: $screen-md) {
        gap: 16px;
      }

      h4 {
        font: 900 40px/0.9 $headline-font;
        color: $white-color;
        text-transform: uppercase;

        @media (min-width: $screen-xxl) {
          font-size: calc(40 / 1440 * 100vw);
        }

        @media (min-width: 1920px) {
          font-size: calc(54 / 1920 * 100vw);
        }

        @media (max-width: 1320px) {
          font-size: 32px;
        }

        @media (max-width: $screen-lg) {
          font-size: 24px;
        }
      }

      p {
        font: 500 16px/1.4 $main-font;
        color: rgba($white-color, .8);
        letter-spacing: -0.8px;

        @media (min-width: $screen-xxl) {
          font-size: calc(16 / 1440 * 100vw);
          letter-spacing: calc(-0.8 / 1440 * 100vw);
        }

        @media (min-width: 1920px) {
          font-size: calc(20 / 1920 * 100vw);
        }

        @media (max-width: 1320px) {
          font-size: 14px;
        }
      }
    }
  }
}
