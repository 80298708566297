.main-projects-section {
  padding: 0 0 150px;
  position: relative;

  @media (min-width: $screen-xxl) {
    padding: 0 0 calc(150 / 1440 * 100vw);
  }

  @media (max-width: $screen-lg) {
    padding: 0 0 80px;
  }

  .light-wrap {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 0;
    margin: auto;
    max-width: 1300px;
    opacity: .8;

    @media (min-width: $screen-xxl) {
      max-width: calc(1300 / 1440 * 100vw);
    }

    @media (max-width: $screen-md) {
      transform: scale(2.5);
      transform-origin: top;
    }

    img {
      width: 100%;
    }
  }

  .col-12 {
    padding: 0;
  }

  .title-wrap {
    text-align: center;
    position: relative;
    z-index: 1;
    margin: 0 auto 56px;

    @media (min-width: $screen-xxl) {
      margin: 0 auto calc(56 / 1440 * 100vw);
    }

    @media (max-width: $screen-lg) {
      margin: 0 auto 48px;
    }

    h2 {
      color: $white-color;
      text-transform: uppercase;
    }
  }

  .items-wrap {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (min-width: $screen-xxl) {
      gap: calc(16 / 1440 * 100vw);
    }

    @media (min-width: 1920px) {
      gap: calc(24 / 1920 * 100vw);
    }

    .item {
      padding: 16px;
      display: flex;
      align-items: center;
      border-radius: 8px;
      border: 2px solid rgba($white-color, .2);
      background-color: rgba($black-color, .2);
      backdrop-filter: blur(20px);

      @media (min-width: $screen-xxl) {
        padding: calc(16 / 1440 * 100vw);
        border-radius: calc(8 / 1440 * 100vw);
        border-width: calc(2 / 1440 * 100vw);
        backdrop-filter: blur(calc(20 / 1440 * 100vw));
      }

      @media (min-width: 1920px) {
        padding: calc(20 / 1920 * 100vw);
      }

      @media (max-width: $screen-lg) {
        flex-direction: column;
        padding: 24px 16px 16px;
        width: 100%;
        gap: 24px;
      }

      &.item-headline {
        padding: 22px 16px;
        background: rgba($black-color, .2) url('../../img/main/main-projects-title-bg.png') top no-repeat;
        background-size: cover;

        @media (min-width: $screen-xxl) {
          padding: calc(22 / 1440 * 100vw) calc(16 / 1440 * 100vw);
        }

        @media (min-width: 1920px) {
          padding: calc(32 / 1920 * 100vw) calc(20 / 1920 * 100vw);
        }

        @media (max-width: $screen-lg) {
          display: none;
        }
      }

      .item-col {
        flex: 1;

        @media (max-width: $screen-lg) {
          width: 100%;
        }

        @media (max-width: $screen-lg) {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        &.item-col-img {
          flex: 0.7;

          @media (max-width: $screen-lg) {
            text-align: center;
            margin: 0 0 16px;
          }

          @media (max-width: $screen-lg) {
            display: initial;
          }
        }

        &.item-col-name {
          flex: 1.2;
        }

        &.item-col-btn {
          flex: 1.8;
          margin-left: 150px;

          @media (min-width: $screen-xxl) {
            margin-left: calc(150 / 1440 * 100vw);
          }

          @media (max-width: 1320px) {
            flex: 1.5;
            margin-left: 50px;
          }

          @media (max-width: $screen-lg) {
            margin-left: 0;
          }

          @media (max-width: $screen-lg) {
            margin: 8px 0 0;
          }

          a {
            width: 100%;
            max-width: 100%;
          }
        }

        img {
          width: 100%;
          max-width: 56px;

          @media (min-width: $screen-xxl) {
            max-width: calc(56 / 1440 * 100vw);
          }

          @media (min-width: 1920px) {
            max-width: calc(72 / 1920 * 100vw);
          }
        }

        h5 {
          color: $white-color;
          opacity: .8;

          @media (max-width: 1320px) {
            font-size: 16px;
          }
        }
      }
    }
  }
}
