header {
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 10;

  @media (max-width: $screen-xxl) {
    width: 100%;
  }

  &.scroll {
    background-color: rgba($black-color, .7);
  }

  .navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    padding: 20px 40px;

    @media (min-width: $screen-xxl) {
      padding: calc(20 / 1440 * 100vw) calc(40 / 1440 * 100vw);
    }

    @media (min-width: 1920px) {
      padding: calc(20 / 1920 * 100vw) calc(80 / 1920 * 100vw);
    }

    @media (max-width: $screen-md) {
      padding: 20px;
    }

    &.collapsed {
      align-items: flex-start;
      height: var(--app-height);
      background-color: $black-color;
    }

    .navbar-brand {
      margin-right: 55px;
      display: flex;
      padding: 0;

      @media (min-width: $screen-xxl) {
        margin-right: calc(55 / 1440 * 100vw);
      }

      @media (min-width: 1920px) {
        margin-right: calc(72 / 1920 * 100vw);
      }

      @media (max-width: $screen-xl) {
        margin-right: 40px;
      }

      @media (max-width: $screen-md) {
        position: relative;
        margin: 0;
      }

      img {
        width: 95px;

        @media (min-width: $screen-xxl) {
          width: calc(95 / 1440 * 100vw);
        }

        @media (min-width: 1920px) {
          width: calc(123 / 1920 * 100vw);
        }

        @media (max-width: $screen-md) {
          width: 72px;
        }
      }
    }

    .navbar-collapse {

      &.show {

        @media (max-width: $screen-lg) {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          transform: translateX(0);
        }
      }

      .navbar-nav {
        margin-right: auto;

        @media (max-width: $screen-lg) {
          gap: 24px;
          margin-bottom: 40px;
        }

        &:hover {

          .nav-item .nav-link {
            opacity: .5;
          }
        }

        .nav-item {
          margin: 0 24px 0 0;

          @media (min-width: $screen-xxl) {
            margin: 0 calc(24 / 1440 * 100vw) 0 0;
          }

          @media (min-width: 1920px) {
            margin: 0 calc(32 / 1920 * 100vw) 0 0;
          }

          @media (max-width: $screen-xl) {
            margin: 0 20px 0 0;
          }

          @media (max-width: $screen-lg) {
            margin: 0;
          }

          &:last-child {
            margin: 0;
          }

          .nav-link {
            font: 400 16px/1 $main-font;
            color: $white-color;
            padding: 0;

            @media (min-width: $screen-xxl) {
              font-size: calc(16 / 1440 * 100vw);
            }

            @media (min-width: 1920px) {
              font-size: calc(20 / 1920 * 100vw);
            }

            @media (max-width: $screen-lg) {
              font-size: 24px;
            }

            &:hover {
              opacity: 1;
              //color: #09A7FF;
            }

            &.active {
              opacity: 1;
              //background: linear-gradient(90deg, #09A7FF -0.03%, #6A2CC0 113.32%);
              //background-clip: text;
              //-webkit-background-clip: text;
              //-webkit-text-fill-color: transparent;
            }

            &.active, &.disabled {
              pointer-events: none;
            }
          }
        }
      }

      .navbar-actions {
        display: flex;
        align-items: center;
        gap: 8px;

        @media (min-width: $screen-xxl) {
          gap: calc(8 / 1440 * 100vw);
        }

        .navbar-socials {
          display: flex;
          align-items: center;
          gap: 8px;

          @media (min-width: $screen-xxl) {
            gap: calc(8 / 1440 * 100vw);
          }

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 56px;
            width: 56px;
            border-radius: 8px;
            border: 1px solid rgba($white-color, .5);

            @media (min-width: $screen-xxl) {
              height: calc(56 / 1440 * 100vw);
              width: calc(56 / 1440 * 100vw);
            }

            @media (min-width: 1920px) {
              height: calc(72 / 1920 * 100vw);
              width: calc(72 / 1920 * 100vw);
            }

            img {
              width: 40px;

              @media (min-width: $screen-xxl) {
                width: calc(40 / 1440 * 100vw);
              }
            }

            &:hover {
              background-color: rgba($white-color, .1);
            }
          }
        }
      }
    }

    .navbar-toggler {
      align-items: center;
      gap: 8px;
      padding: 0;
      border: 0;
      outline: none;
      box-shadow: none;
      position: relative;
      border-radius: 0;
      height: 34px;
      z-index: 22;

      @media (max-width: $screen-lg) {
        display: flex;
      }

      @media (max-width: $screen-md) {
        height: 24px;
      }

      &.collapsed {

        span.icon-bar {
          background-color: #D9D9D9;
        }
      }

      &:not(.collapsed) {

        .icon-bar {

          &:nth-child(1) {
            transform: translateY(3px) rotate(45deg);
          }

          &:nth-child(2) {
            transform: translateY(-3px) rotate(-45deg);
          }
        }

        ~ .navbar-toggler-text::before {
          content: 'Close';
        }

        ~ .navbar-toggler-overlay {
          display: block;
        }
      }

      .text-wrap p {
        color: $white-color;
      }

      .icons-wrap {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      span.icon-bar {
        position: relative;
        z-index: 3;
        display: block;
        width: 35px;
        height: 1px;
        background-color: #D9D9D9;
        transition: .2s cubic-bezier(0.7, 0, 0, 0.7) opacity, transform;
      }
    }
  }
}
