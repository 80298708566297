@import "assets/scss/vars/variables";

.notfoundSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 30px;
  text-align: center;

  h2 {
    font: 700 150px/1 $headline-font;
    margin-bottom: 20px;
    color: $white-color;

    @media (max-width: $screen-md) {
      font-size: 100px;
    }
  }

  p {
    font: 300 24px/1.4 $main-font;
    color: $white-color;
    margin: 0 0 20px;

    @media (max-width: $screen-lg) {
      font-size: 20px;
    }

    @media (max-width: $screen-md) {
      font-size: 18px;
    }
  }

  .link {
    font-size: 20px;
    font-weight: 600;
    position: relative;
    text-decoration: none;

    &::after {
      content: '';
      display: block;
      width: 0;
      height: 2px;
      left: 0;
      bottom: -4px;
      transition: $fast-transition;
    }

    &:hover::after {
      width: 100%;
    }
  }
}
